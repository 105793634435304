import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';
import type { IFormSchema } from 'src/components/Form';
import dayjs from 'dayjs';

export const columns: QueryTableColumnProps = [
  {
    title: '部门名称',
    dataIndex: 'deptName',
    key: 'deptName',
    width: 200,
  },
  {
    title: '排序',
    dataIndex: 'orderNum',
    key: 'orderNum',
    width: 150,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
    render: (createTime: string) => {
      return dayjs(createTime).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  deptName: {
    type: 'string',
    title: '岗位名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入岗位名称',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
};

export const operationSchema: IFormSchema = {
  parentId: {
    type: 'string',
    title: '父级部门',
    'x-component': 'TreeSelect',
    'x-component-props': {
      placeholder: '请选择父级部门',
    },
    'x-decorator': 'FormItem',
    'x-validator': [{ required: true, message: '请选择父级部门' }],
  },
  deptName: {
    type: 'string',
    title: '部门名称',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入部门名称',
    },
    'x-validator': [{ required: true, message: '请输入部门名称' }],
  },
  orderNum: {
    type: 'number',
    title: '排序',
    'x-component': 'NumberPicker',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入排序',
    },
    'x-validator': [{ required: true, message: '请输入排序' }],
  },
};

import { useRef, useMemo, useEffect, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { message, Modal } from 'antd';
import type { ModalProps } from 'antd';
import { Input, NumberPicker, TreeSelect } from '@formily/antd-v5';
import { Form, FormRef } from 'src/components/Form';
import { IDeptItem, IUpdateDeptReq } from 'src/api/types/system';
import { updateDept, createDept } from 'src/api/clients/system';
import { onFormMount } from '@formily/core';
import { operationSchema } from './schema';
import { ISelectTree } from 'src/interface';

interface IProps extends ModalProps {
  data?: IDeptItem;
  tree?: IDeptItem[];
  levelId?: number;
}

export function DeptModal(props: IProps) {
  const { open, onOk, onCancel, data, tree, levelId, ...extra } = props;
  const [loading, { setTrue, setFalse }] = useBoolean(false);
  const formRef = useRef<FormRef | null>(null);
  const [formMounted, setFormMounted] = useState(false);

  const isEdit = !!data?.deptId;

  const onCancelHandle = useMemoizedFn((e) => {
    const formInstance = formRef.current?.getFormInstance();
    formInstance?.reset();
    onCancel?.(e);
  });

  const selectNode = useMemo(() => {
    const transformData = (data: IDeptItem): ISelectTree => {
      const result: ISelectTree = {
        label: data.deptName ?? '',
        value: Number(data?.deptId),
        children: data.children?.map(transformData),
      };
      return result;
    };
    return tree?.map(transformData);
  }, [tree]);

  const onSubmit = useMemoizedFn(async (e) => {
    const formInstance = formRef.current?.getFormInstance();
    const values = await formInstance?.submit<IDeptItem>();
    const { ...rest } = values ?? {};
    const bodyData = {
      ...rest,
    } as IUpdateDeptReq;
    if (isEdit) {
      bodyData.deptId = data?.deptId;
    }
    setTrue();
    const request = isEdit ? updateDept : createDept;
    try {
      const res = await request(bodyData as IUpdateDeptReq);
      if (res) {
        message.success(`${isEdit ? '更新' : '添加'}成功`);
        onCancelHandle(e);
        onOk?.(e);
      }
    } finally {
      setFalse();
    }
  });

  const formOptions = useMemo(() => {
    return {
      effects() {
        onFormMount(() => {
          setFormMounted(true);
        });
      },
    };
  }, []);

  useEffect(() => {
    if (formMounted) {
      const formInstance = formRef.current?.getFormInstance();
      formInstance?.getFieldState('parentId', (field) => {
        if (field.componentProps) {
          field.componentProps.treeData = [{ label: '顶级部门', value: 0, children: selectNode }];
        }
      });
    }
  }, [formMounted, selectNode, isEdit]);

  useEffect(() => {
    if (formMounted) {
      const formInstance = formRef.current?.getFormInstance();
      const { deptName, orderNum, parentId } = data || {};
      formInstance?.setValues({
        deptName,
        orderNum,
        parentId: parentId || levelId || 0,
      });
    }
  }, [formMounted, data, levelId]);

  return (
    <Modal
      open={open}
      title={isEdit ? '编辑部门' : '新增部门'}
      onOk={onSubmit}
      width={500}
      onCancel={onCancelHandle}
      confirmLoading={loading}
      {...extra}>
      <Form
        className="py-4"
        ref={formRef}
        components={{ Input, NumberPicker, TreeSelect }}
        schema={operationSchema}
        formOptions={formOptions}
        hideAction
        layout={{ labelCol: 5, wrapperCol: 19 }}
      />
    </Modal>
  );
}
